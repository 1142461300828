import { FC, memo, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
// kendo
import { Button, Modal } from '@/components';
import { Icons } from '@/components/icons';
// components
import EmailModal from './emailModal/emailModal';
import { AccountsMainPanel } from '@/features/Accounts/accountsSubviews/AccountDetail/components/accountsMainPanel/AccountsMainPanel';
import { LogCallModal } from './logCallModal/LogCallModal';
import { EmailContentsModal } from '../emailContentsModal/EmailContentsModal';
import { ChatBox } from './chatBox';
import { NotesTextBox } from './notesTextBox';
// state
import { useAccountSelector } from '@/features/Accounts/accountSlice';
// utils
import { notesService } from '@/services/notesService';
import { paymentService } from '@/services/paymentService';
import { accountsService } from '@/services/accountsService';
import { Email, Note, Payment, Messages, customerService, Call } from '@/services/customerService';
import { DateFormat, extractTextFromHtmlString, formatDate } from '@/utils/helpers/general';
import { formatPhone } from '@/utils/helpers/formatting';
// interfaces
import { CustomerActivity, ActivityType } from './activityPanelInterfaces';
import { ActivityLog } from '@/interfaces';
// style
import styles from './activityPanel.module.scss';
import { DCS_BLUE } from '@/mui/theme/colors';
import { messages } from '@progress/kendo-react-inputs/dist/npm/messages';

const ActivityPanel: FC = () => {
  const params = useParams();
  const accountInformation = useAccountSelector((s) => s.accountInformation);
  if (!accountInformation) return <></>;
  const contactInformation = useAccountSelector((s) => s.contactInformation);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activityData, setActivityData] = useState<CustomerActivity[] | undefined>(undefined);
  const [pinnedNotes, setPinnedNotes] = useState<Note[] | undefined>(undefined);
  const [logData, setLogData] = useState<CustomerActivity[]>([]);
  const [loadingLogData, setLoadingLogData] = useState(false);
  const [selectedEmailRecId, setSelectedEmailRecId] = useState<number | null>(null);
  const [selectedLog, setSelectedLog] = useState<ActivityLog | null>();
  const [errors, setErrors] = useState<{ error: boolean; errorMessage: string }>({
    error: false,
    errorMessage: '',
  });
  const panelRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = useState(0);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [logCallModalOpen, setLogCallModalOpen] = useState(false);
  const { buyer } = accountInformation;
  const isLegal = !!accountInformation?.legalStatus;
  const appBuyerRecId = buyer.appBuyerRecId!;
  const colRecId = Number(params.colRecId);

  const cannotContactBuyerAndCobuyer =
    (!!contactInformation?.buyer?.noEmail ?? false) && !!contactInformation?.coBuyer?.noEmail;

  const currentTab =
    (searchParams.get('activityType') as ActivityType | undefined) ?? ActivityType.All;

  const setCurrentTab = (activityType: ActivityType) => {
    searchParams.set('activityType', activityType);
    setSearchParams(searchParams);
  };

  const updatePinnedNote = async (note: Note) => {
    const pinnedNoteActivityData = activityData?.find(
      (value) => value.activityType === ActivityType.Note && value.activity === note
    );
    const pinnedNote = pinnedNoteActivityData?.activity as Note;
    await customerService
      .updatePinnedNote({
        recId: note.recId!,
        pinned: !note.pinned,
      })
      .then((res) => {
        if (!note.pinned) {
          setActivityData((prevActivityData) => {
            if (prevActivityData) {
              const newActivityData = prevActivityData!.filter((value) => value.id !== note.recId);
              return [
                ...newActivityData,
                {
                  id: res.recId,
                  activity: {
                    ...note,
                    pinned: res.pinned,
                    pinnedOn: res.pinnedBy,
                    pinnedOnUtc: formatDate(res.pinnedOnUtc),
                  },
                  activityType: ActivityType.Note,
                  timestamp: note.updatedUtc,
                } as CustomerActivity,
              ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
            }
            return [
              {
                id: res.recId,
                activity: {
                  ...note,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
                activityType: ActivityType.Note,
                timestamp: note.updatedUtc,
              } as CustomerActivity,
            ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
          });
          setPinnedNotes((prevNotes) => {
            if (prevNotes) {
              return [
                ...prevNotes,
                {
                  ...pinnedNote,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
              ].sort!((a, b) => (a.pinnedOnUtc! > b.pinnedOnUtc! ? -1 : 1));
            }

            return [
              {
                ...pinnedNote,
                pinned: res.pinned,
                pinnedOn: res.pinnedBy,
                pinnedOnUtc: formatDate(res.pinnedOnUtc),
              },
            ];
          });
        } else {
          setPinnedNotes((prevNotes) =>
            prevNotes?.filter((prevNote) => prevNote.recId !== note.recId)
          );

          setActivityData((prevActivityData) => {
            if (prevActivityData) {
              const newActivityData = prevActivityData.filter((value) => value.id !== note.recId);
              return [
                ...newActivityData,
                {
                  id: res.recId,
                  activity: {
                    ...note,
                    pinned: res.pinned,
                    pinnedOn: res.pinnedBy,
                    pinnedOnUtc: formatDate(res.pinnedOnUtc),
                  },
                  activityType: ActivityType.Note,
                  timestamp: note.updatedUtc,
                } as CustomerActivity,
              ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
            }
            return [
              {
                id: res.recId,
                activity: {
                  ...note,
                  pinned: res.pinned,
                  pinnedOn: res.pinnedBy,
                  pinnedOnUtc: formatDate(res.pinnedOnUtc),
                },
                activityType: ActivityType.Note,
                timestamp: note.updatedUtc,
              } as CustomerActivity,
            ].sort!((a, b) => (a.timestamp! > b.timestamp! ? -1 : 1));
          });
        }
      });
  };

  const initData = async () => {
    try {
      const customerActivity = await customerService.getCustomerActivity(colRecId);
      const activityArr: CustomerActivity[] = [];

      Object.entries(customerActivity).forEach(([key, val]) => {
        if (key === 'emails') {
          val.forEach((email: Email) => {
            activityArr.push({
              id: email.recId,
              activityType: ActivityType.Email,
              activity: email,
              timestamp: email.timestamp || '',
            });
          });
        } else if (key === 'messages') {
          val.forEach((message: Messages) => {
            activityArr.push({
              id: message.ecomRecId,
              activityType: ActivityType.Message,
              activity: message,
              timestamp: message.timestamp || '',
            });
          });
        } else if (key === 'notes') {
          const newPinnedNotes = val.filter((note: Note) => note.pinned);
          setPinnedNotes(newPinnedNotes);

          val.forEach((note: Note) => {
            activityArr.push({
              id: note.recId!,
              activityType: ActivityType.Note,
              activity: note,
              timestamp: note.updatedUtc || '',
            });
          });
        } else if (key === 'payments') {
          val.forEach((payment: Payment) => {
            activityArr.push({
              id: payment.recId!,
              activityType: ActivityType.Payment,
              activity: payment,
              timestamp: payment.timestamp || '',
            });
          });
        } else if (key === 'calls') {
          val.forEach((call: Call) => {
            activityArr.push({
              id: call.recId,
              activityType: ActivityType.Call,
              activity: call,
              timestamp: call.timestamp || '',
            });
          });
        }
      });

      setActivityData(activityArr.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1)));
    } catch (error) {
      console.error(error);
      setErrors({ error: true, errorMessage: 'There was an issue loading the customer activity' });
    }
  };

  const handleNoteSubmit = async (newNote: string): Promise<boolean> => {
    let success = false;
    if (newNote === '') {
      return success;
    }

    try {
      await notesService.insertNote(appBuyerRecId, colRecId, newNote);
      await initData();

      success = true;
    } catch (error) {
      console.error(error);
    }
    return success;
  };

  const RenderEmail = memo(({ email }: { email: Email }) => {
    return (
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Mail />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div>Email from {email.sentBy}</div>
              </div>
              {!!email.subject && (
                <div>
                  <div>
                    {email.subject ? `"${email.subject}" - ` : ''}
                    <span
                      className={styles.link}
                      onClick={() => setSelectedEmailRecId(email.recId)}
                    >
                      Click to view
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(email.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    );
  });

  const RenderSMS = memo(({ sms }: { sms: Messages }) => {
    let messageSummaryClassName = styles.type;
    let messageSummary = `Message sent to ${sms.sentTo} from ${sms.sentBy}`;
    let messageIconContainerClass = styles.messageIconContainer;
    if (sms.isDirectConsentError) {
      messageSummaryClassName = styles.smsDirectConsentFailure;
      messageSummary = 'Text Message Failed to Send';
      messageIconContainerClass = styles.messageIconContainerDirectConsentFailure;
    } else if (sms.status === 'failed' || sms.status === 'undelivered') {
      messageSummaryClassName = styles.smsDirectConsentFailure;
      messageSummary = `Message failed with error: ${
        sms.errorMessage === null ? sms.errorCode : sms.errorMessage
      }`;
      messageIconContainerClass = styles.messageIconContainerDirectConsentFailure;
    } else {
      messageSummary = `Message received from ${sms.sentBy}`;
      messageIconContainerClass = styles.messageIconContainerInboundSms;
    }
    console.log ('messages!!!!!!', messageSummary, sms)


    return (
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={messageIconContainerClass}>
            <Icons.Message />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div className={messageSummaryClassName}>{messageSummary}</div>
              </div>
              {sms.isDirectConsentError ? (
                <div>
                  Failed to send, Customer opted out of text communication with phone number:{' '}
                  {formatPhone(sms.sentToNumber ?? '')}
                  <br />
                  <div>"{sms.body}"</div>
                </div>
              ) : (
                <div>{sms.body}</div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(sms.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    );
  });

  const RenderNote = memo(({ note }: { note: Note }) => {
    return (
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Note />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.row}>
                <div className={styles.messageHeader}>Note created by {note.createdByString}</div>
                <div className={styles.pinRow}>
                  {note.pinned && (
                    <div className={styles.disclaimer}>
                      {note.pinnedByString} - {formatDate(note.pinnedOnUtc)}
                    </div>
                  )}
                  <div
                    className={styles.pin}
                    onClick={() => updatePinnedNote(note)}
                    style={{ fill: note.pinned ? DCS_BLUE : '#b1b1b1' }}
                  >
                    <Icons.Pin />
                  </div>
                </div>
              </div>
              <div>{note.note}</div>
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(note.updatedUtc, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    );
  });

  const RenderPayment = memo(({ payment }: { payment: Payment }) => {
    const [paymentClickLoading, setPaymentClickLoading] = useState(false);
    const [paymentReceiptLoading, setPaymentReceiptLoading] = useState(false);

    const handlePaymentClick = async (payment: Payment) => {
      setPaymentClickLoading(true);

      if (payment.pmtRecId! === 0 || payment.pmtRecId! === null) {
        toast.error('No Receipt Id to Send');
        setPaymentClickLoading(false);
        return;
      }

      await paymentService
        .sendReceipt(payment.pmtRecId!)
        .then(() => {
          toast.success('Receipt Sent');
        })
        .catch(() => toast.error('Unable to send receipt'))
        .finally(() => setPaymentClickLoading(false));
    };

    const handlePaymentReprint = async (payment: Payment) => {
      setPaymentReceiptLoading(true);

      try {
        const receiptUrl = await paymentService.getReceiptUrl(payment.pmtRecId!);
        if (!receiptUrl) throw new Error();
        window.open(receiptUrl);
      } catch (e) {
        toast.error('Unable to reprint receipt');
      } finally {
        setPaymentReceiptLoading(false);
      }
    };

    // Payments should only be in 'New' status for a few seconds, when a user is entering cc/ach details
    // If they're around long enough to be seen here, that probably means they exited the cc/ach iframe without completing the payment
    // Ideally we should be cleaning those up, but that comes with a plethora of edge cases, so just ignore them
    if (payment.pmtStatus === 'New') return null;

    let paymentDetails = (
      <div>
        <span className={styles.title}>{payment.pmtStatus}</span> - {payment.paidIn}{' '}
        {payment.paidBy} payment of <span className={styles.success}>${payment.totalApplied}</span>
      </div>
    );

    if (payment.pmtStatus !== 'Done-Posted' && payment.pmtStatus !== 'Posted-Done') {
      const totalClassName = payment.pmtStatus === 'Approved' ? styles.warning : styles.error;
      paymentDetails = (
        <div>
          <span className={styles.title}>{payment.pmtStatus}</span> - {payment.paidIn}{' '}
          {payment.paidBy} payment of{' '}
          <span className={totalClassName}>${payment.totalApplied}</span>
        </div>
      );
    } else {
      if (payment.paymentType === 'CPI ADJ') {
        paymentDetails = (
          <div>
            CPI adjustment of <span className={styles.success}>${payment.carPmt}</span>
          </div>
        );
      }

      if (payment.paymentType === 'PrinOnly') {
        paymentDetails = (
          <div>
            Principal payment of <span className={styles.success}>${payment.carPmt}</span>
          </div>
        );
      }
    }

    return (
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Message />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div>
                {paymentDetails}
                {(payment.pmtStatus === 'Done-Posted' || payment.pmtStatus === 'Posted-Done') && (
                  <div className={styles.actionDiv}>
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <Button
                        label="Resend Receipt"
                        secondary
                        onClick={() => handlePaymentClick(payment)}
                        loading={paymentClickLoading}
                      />
                      <Button
                        label="Print Receipt"
                        secondary
                        onClick={() => handlePaymentReprint(payment)}
                        loading={paymentReceiptLoading}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(payment.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    );
  });

  const RenderCall = memo(({ call }: { call: Call }) => {
    return (
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Message />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div
                  className={styles.type}
                >{`Call from ${call.employeeName} to ${call.toName}`}</div>
              </div>
              <div>{extractTextFromHtmlString(call.body)}</div>
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(call.timestamp, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    );
  });

  const RenderLog = memo(({ log }: { log: ActivityLog }) => {
    const logBody = log.detailLong ? (
      <div className={styles.link} onClick={() => setSelectedLog(log)}>
        {log.detailShort}
      </div>
    ) : (
      <div>{log.detailShort}</div>
    );

    return (
      <div className={styles.message}>
        <div className={styles.messageLeft}>
          <div className={styles.messageIconContainer}>
            <Icons.Message />
          </div>
          <div>
            <div className={styles.messageBody}>
              <div className={styles.messageHeader}>
                <div className={styles.messageHeader}>
                  <div>{log.transType}</div>
                </div>
              </div>
              {logBody}
            </div>
          </div>
        </div>
        <div className={styles.messageRight}>
          {formatDate(log.contactOn, { pattern: DateFormat.DateTimeDayOfWeek, utc: false })}
        </div>
      </div>
    );
  });

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (currentTab !== ActivityType.Log) return;
    setLoadingLogData(true);
    accountsService
      .getActivityLog(colRecId)
      .then((res) => {
        const activityLog = res.map((log) => ({
          id: log.recId,
          activityType: ActivityType.Log,
          activity: log,
          timestamp: log.contactOn || '',
        }));
        setLogData(activityLog);
      })
      .finally(() => {
        setLoadingLogData(false);
      });
  }, [currentTab]);

  useEffect(() => {
    if (!panelRef?.current?.clientHeight) {
      return;
    }
    setPanelHeight(panelRef?.current?.clientHeight);
  }, [panelRef?.current?.clientHeight]);

  if (errors.error) {
    return <div>{errors.errorMessage}</div>;
  }

  const displayAll = currentTab !== ActivityType.Message;

  useEffect(() => {
    currentTab;
  }, [currentTab]);

  const navBarItems = Object.values(ActivityType).map((type) => ({
    title: type,
    isActive: currentTab === type,
    onClick: () => setCurrentTab(type),
  }));

  const isLoading =
    (currentTab === ActivityType.Log && loadingLogData) ||
    (currentTab !== ActivityType.Log && activityData === undefined && !errors.error);

  return (
    <AccountsMainPanel
      navBarTitle="Activity"
      navBarItems={navBarItems}
      navBarItemsStyle={{ justifyContent: 'center', gap: '5%' }}
      loading={isLoading}
    >
      <>
        {currentTab === ActivityType.Note && <NotesTextBox handleNoteSubmit={handleNoteSubmit} />}

        {currentTab === ActivityType.Message && (
          <ChatBox
            activityData={activityData!}
            appBuyerRecId={appBuyerRecId}
            initData={initData}
            height={panelHeight}
            loading={activityData === undefined && !errors.error}
            isLegal={isLegal}
            canTextBuyer={!contactInformation?.buyer?.noText}
          />
        )}
        {currentTab === ActivityType.Email && (
          <div className={styles.buttonHeader}>
            <div className={styles.button}>
              <Button
                label="Compose Email"
                disabled={isLegal || cannotContactBuyerAndCobuyer}
                onClick={() => setEmailModalOpen(true)}
                loading={activityData === undefined}
              />

              <div className={styles.disclaimer}>
                {isLegal
                  ? 'Cannot contact customer with legal status'
                  : cannotContactBuyerAndCobuyer
                  ? 'Both buyer and co-buyer have no email preference on'
                  : undefined}
              </div>
            </div>
          </div>
        )}
        {currentTab === ActivityType.Call && (
          <div className={styles.buttonHeader}>
            <div className={styles.button}>
              <Button label="Log Call" onClick={() => setLogCallModalOpen(true)} />
            </div>
          </div>
        )}
        <div className={styles.activities} style={{ display: !displayAll ? 'none' : 'flex' }}>
          {currentTab === 'All' && !!pinnedNotes?.length && (
            <>
              <h3 className={styles.pinnedNotesHeader}>Pinned Notes</h3>

              {pinnedNotes.map((note, idx) => (
                <RenderNote note={note} key={`pn-${idx}`} />
              ))}

              <h3 className={styles.pinnedNotesHeader}>Rest of Activity</h3>
            </>
          )}
          {currentTab !== ActivityType.Log &&
            activityData &&
            activityData.map((activity, idx) => {
              if (activity.activityType === currentTab || currentTab === 'All') {
                switch (activity.activityType) {
                  case ActivityType.Email:
                    return <RenderEmail email={activity.activity as Email} key={`Email${idx}`} />;
                  case ActivityType.Message:
                    return <RenderSMS sms={activity.activity as Messages} key={`Message${idx}`} />;
                  case ActivityType.Note:
                    return <RenderNote note={activity.activity as Note} key={`Note${idx}`} />;
                  case ActivityType.Payment:
                    return (
                      <RenderPayment payment={activity.activity as Payment} key={`Payment${idx}`} />
                    );
                  case ActivityType.Call:
                    return <RenderCall call={activity.activity as Call} key={`Call${idx}`} />;
                }
              }
              return <></>;
            })}
          {currentTab === ActivityType.Log &&
            logData.map((log, idx) => {
              return <RenderLog log={log.activity as ActivityLog} key={`log${idx}`} />;
            })}
        </div>
        {!!selectedEmailRecId && (
          <EmailContentsModal
            isOpen={true}
            onCloseButtonClick={() => setSelectedEmailRecId(null)}
            emailRecId={selectedEmailRecId}
          />
        )}
        {emailModalOpen && (
          <EmailModal
            colRecId={colRecId}
            initEmailData={initData}
            open={emailModalOpen}
            onClose={() => setEmailModalOpen(false)}
            canEmailBuyer={!contactInformation?.buyer.noEmail}
            canEmailCoBuyer={!contactInformation?.coBuyer.noEmail}
          />
        )}
        {logCallModalOpen && (
          <LogCallModal
            colRecId={colRecId}
            reloadActivity={initData}
            open={logCallModalOpen}
            onClose={() => setLogCallModalOpen(false)}
          />
        )}
        {!!selectedLog && (
          <Modal
            isOpen={true}
            centerModal
            closeButton
            onCloseButtonClick={() => setSelectedLog(null)}
            onDimmerClick={() => setSelectedLog(null)}
            title={[
              formatDate(selectedLog.contactOn),
              selectedLog.detailShort ?? selectedLog.transType,
            ].join(' - ')}
          >
            {selectedLog.detailLong.startsWith('<') ? (
              <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(selectedLog.detailLong) }}
              />
            ) : (
              <div>{selectedLog.detailLong}</div>
            )}
          </Modal>
        )}
      </>
    </AccountsMainPanel>
  );
};

export default ActivityPanel;
