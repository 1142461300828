import { FC } from 'react';
import { NavLink, useParams } from 'react-router-dom';
// kendo
import { BackLink } from '@components/backLink/BackLink';
// components
import SaleDetailsRouter from './SaleDetailsRouter';
// state
import { useSalesSelector } from '../../salesSlice';
// style
import styles from './SaleDetailsContainer.module.scss';
import FeatureFlag from '@/env/FeatureFlag';
import useEmailFeatureFlag from '@/env/useEmailFeatureFlag';

/** @deprecated convert to navtabs */
const SaleDetailsContainer: FC = () => {
  const params = useParams();
  /** @deprecated use common-convention: `new` */
  const newSale = params.id === 'newSale';
  const saleData = useSalesSelector((s) => s.saleData);
  const saleDataLoading = useSalesSelector((s) => s.saleDataLoading);
  const emailFeatureFlag = useEmailFeatureFlag();

  const linkClassName = ({ isActive }: { isActive: boolean }) =>
    isActive ? styles.navLinkActive : styles.navLink;

  const headerText = [
    saleData.sale?.stocknum ? `#${saleData.sale?.stocknum}` : null,
    saleData.sale?.vehYMM,
    saleData.sale?.salestatus,
    saleData.sale?.saletype,
    saleData.sale?.bNames,
  ]
    .filter((titlePiece) => !!titlePiece)
    .join(' - ');

  return (
    <>
      <div className={styles.backLinkContainer}>
        <BackLink to="/sales" title="Back to list" />
      </div>
      <div className={styles.container}>
        <header className={styles.header}>
          {!saleDataLoading && !newSale && <h1 className={styles.headerText}>{headerText}</h1>}
        </header>
        <div className={styles.bodyContainer}>
          <nav className={styles.salesNav}>
            <h3 className={styles.salesNavHeader}>Sales</h3>
            <div className={styles.salesNavLinks}>
              {!saleDataLoading && (
                <>
                  <NavLink to="./" className={linkClassName} end>
                    Sale Management
                  </NavLink>
                  {!newSale && (
                    <>
                      <NavLink to="creditReports" className={linkClassName}>
                        Credit Reports
                      </NavLink>
                      <NavLink to="application" className={linkClassName}>
                        Application
                      </NavLink>
                      <NavLink to="verification" className={linkClassName}>
                        Verification
                      </NavLink>
                      <NavLink to="vehicleAndTempTag" className={linkClassName}>
                        Vehicle & Temp Tag
                      </NavLink>
                      <NavLink to="terms" className={linkClassName}>
                        Terms/Quotes
                      </NavLink>
                      <NavLink to="documents" className={linkClassName}>
                        Documents
                      </NavLink>
                      {saleData.sale?.saletype == 'Lease' && (
                        <NavLink to="creditEducation" className={linkClassName}>
                          Credit Education
                        </NavLink>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </nav>
          <div className={styles.body}>
            <SaleDetailsRouter />
          </div>
        </div>
      </div>
    </>
  );
};

export default SaleDetailsContainer;
